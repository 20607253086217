<template>
  <el-container class="main-container">
    <el-aside width="200px">
      <el-menu :default-active="showIndex">
        <router-link to="/">
          <div class="margin30">
            <img src="../assets/logo.png" alt="" srcset="" width="50%" />
          </div>
        </router-link>
        <router-link to="/view">
          <el-menu-item index="1" @click="showIndex = '1'">门店</el-menu-item>
        </router-link>
        <router-link to="/shop">
          <el-menu-item index="2" @click="showIndex = '2'">看点</el-menu-item>
        </router-link>
        <router-link to="/map">
          <el-menu-item index="3" @click="showIndex = '3'">地图</el-menu-item>
        </router-link>
        <router-link to="/my">
          <el-menu-item index="4" @click="showIndex = '4'">我的</el-menu-item>
        </router-link>
      </el-menu>
      <div class="others">
        <div class="other-info">
          <div class="copyright">
            2022 熟客<br />
            <a
              style="color: #fff"
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              沪ICP备17018404号-1<br />
            </a>
            <img src="../assets/police.png" alt="" class="image" />
            沪公安备案<br />
            31011202012829号
          </div>
          <div class="address">
            办公地址：上海市闵行区联航路1505弄1号308-1室
          </div>
        </div>
        <div class="other-nav">
          <div class="row">
            <div class="col-50">
              <router-link to="/about">关于我们</router-link>
            </div>
            <div class="col-50">
              <a
                href="https://www.shuke.shop/UploadImage/read/protocol.txt"
                target="_blank"
                rel="noopener noreferrer"
                >隐私政策</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-50">
              <a
                href="https://www.shuke.shop/UploadImage/read/private.txt"
                target="_blank"
                rel="noopener noreferrer"
                >用户服务协议</a
              >
            </div>
            <div class="col-50">
              <router-link to="/join">加入我们</router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-50" style="color: #fff" @click="findMe">
              地图找到我们
            </div>
            <div class="col-50">
              <router-link to="/company">公司资质</router-link>
            </div>
          </div>
        </div>
      </div>
    </el-aside>
    <router-view></router-view>
  </el-container>
</template>

<script>
export default {
  name: "App",
  data() {
    return { showIndex: "1" };
  },
  methods: {
    findMe() {
      this.$router.push({
        path: "/find",
      });
    },
  },
};
</script>
<style >
* {
  margin: 0;
  padding: 0;
}
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #fff;
}
video {
  background: #090808;
}
</style>
<style scoped>
#app {
  min-width: 1024px;
  min-height: 100vh;
}
.main-container {
  min-height: 100vh;
  overflow: hidden;
  padding-left: 200px;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
  background: #090808;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.el-menu {
  background: transparent;
  color: #fbf9f9;
  border: 0;
}
.el-menu-item {
  color: #fbf9f9;
  text-align: center;
  font-size: 16px;
}
.el-menu-item:hover,
.el-menu-item.is-active {
  background: #a6cdfe;
}
.margin30 {
  text-align: center;
  margin: 10px 0 30px 0;
}
.others {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
}
.other-info {
  font-size: 13px;
  color: #fbf9f9;
}
.copyright {
  line-height: 1.8;
  text-align: center;
  margin-bottom: 10px;
}
.copyright .image {
  width: 15px;
}
.address {
  padding: 20px 0;
  text-align: left;
  margin: 0 10px;
  position: relative;
}
.address::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border: 1px solid #333;
}
.address::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #333;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.row .col-50 {
  width: 50%;
  text-align: center;
  font-size: 14px;
}
.col-50 a {
  color: #fff;
}
</style>
