import { render, staticRenderFns } from "./DesktopPage.vue?vue&type=template&id=345fc024&scoped=true"
import script from "./DesktopPage.vue?vue&type=script&lang=js"
export * from "./DesktopPage.vue?vue&type=script&lang=js"
import style0 from "./DesktopPage.vue?vue&type=style&index=0&id=345fc024&prod&lang=css"
import style1 from "./DesktopPage.vue?vue&type=style&index=1&id=345fc024&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345fc024",
  null
  
)

export default component.exports