<style scoped>
.el-main {
  position: relative;
  padding: 0;
}
.container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding-top: 20px;
  background: #c5c5c8;
  text-align: center;
}
.info {
  margin: 0 auto;
  height: 300px;
  width: 600px;
  border-radius: 14px;
  position: relative;
}
.background-image {
  width: 600px;
  height: 300px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 14px;
}
.info .client-info {
  position: absolute;
  bottom: 20px;
  margin-left: 20px;
}
.other-info .address {
  color: #fff;
  font-size: 14px;
}
.client-info {
  display: flex;
}
.client-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.other-info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.other-info .title {
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.video-list {
  margin: 23px auto;
  border-radius: 14px;
  background: #fff;
  width: 600px;
}
.video-container .client-info {
  color: #000;
  margin-left: 16px;
  padding-top: 23px;
}
.video-container .client-info .title {
  color: #000;
  font-size: 17px;
  margin-bottom: 15px;
}
.video-container .video-info {
  margin-left: 86px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.video-title {
  font-weight: 600;
  font-size: 15px;
  margin: 13px 0;
  text-align: left;
}
.video-container .video {
  width: 480px;
  height: 240px;
}
.comment-box {
  height: 40px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.comment-box .icon {
  width: 20px;
  height: 20px;
}
.star,
.comment,
.share {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="container">
        <div class="info">
          <img
            :src="clientInfo.cszpath"
            alt=""
            srcset=""
            class="background-image"
          />
          <div class="client-info">
            <img :src="clientInfo.mtzpath" class="client-icon" />
            <div class="other-info">
              <div class="title">{{ clientInfo.clientname }}</div>
              <div class="address">{{ clientInfo.addr }}</div>
            </div>
          </div>
        </div>
        <div class="video-list" v-if="list.length !== 0">
          <div
            class="video-container"
            v-for="(video, index) in list"
            :key="index"
          >
            <div class="client-info">
              <img :src="video.mtzPath" class="client-icon" />
              <div class="other-info">
                <div class="title">{{ video.clientName }}</div>
                <div class="date">2023-05-05 22:22</div>
              </div>
            </div>
            <div class="video-info">
              <div class="video-title">{{ video.video_title }}</div>
              <video
                webkit-playsinline
                playsinline
                class="video"
                controls
                muted="muted"
                controlslist="nodownload noremoteplayback noplaybackrate"
              >
                <source :src="video.video_url" />
                您的浏览器不支持 HTML5 video 标签。
              </video>
            </div>
            <div class="comment-box">
              <div class="star">
                <el-link @click="starClick(video, index)">
                  <img
                    src="../assets/star.png"
                    class="icon"
                    srcset=""
                    v-if="!video.collection"
                  />
                  <img
                    src="../assets/star-selected.png"
                    class="icon"
                    srcset=""
                    v-else
                  />
                </el-link>
                {{ video.collectionNum || 0 }}
              </div>
              <div class="comment">
                <img src="../assets/comment.png" class="icon" srcset="" />
                {{ video.commentNum || 0 }}
              </div>
              <div class="share">
                <el-popover
                  class="share-code-popover"
                  placement="right"
                  width="300"
                  trigger="click"
                >
                  <div class="code-container">
                    <div class="label">扫码下载APP后分享给好友</div>
                    <img src="../assets/code.jpeg" class="code-image" alt="" />
                  </div>
                  <img
                    src="../assets/share.png"
                    class="icon"
                    srcset=""
                    slot="reference"
                  />
                </el-popover>
                {{ video.forwardnum }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  data() {
    return { list: [], clientInfo: {} };
  },
  mounted() {
    this.fetchDataByClientId(this.$route.query.clientId);
  },
  methods: {
    async setLike() {
      //  const response = await fetch("PayShuKe/client_iphone_aupca/collection");
    },
    async fetchClientDetail(id) {
      const formData = new FormData();
      formData.append("clientId", id);
      const response = await fetch("/PayShuKe/MstClientController/selectClientById", {
        body: formData,
        method: "post",
      });
      const jsonData = await response.json();
      console.log(jsonData);
      this.clientInfo = jsonData.data;
    },
    async fetchDataByClientId(id) {
      this.fetchClientDetail(id);
      const response = await fetch(
        `/PayShuKe/SignController/getUserArticleList?type=1&clientId=${id}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.list = jsonData.data.video_json;
    },
    async starClick(video, index) {
      if (!this.$root.$children[0].isLogin) {
        this.$message.error("需要登录");
        return;
      }
      const response = await fetch(
        `/PayShuKe/client_iphone_aupca/collection?id=${video.xh}&userId=${this.$root.$children[0].userInfo.xh}`
      );
      const jsonData = await response.json();
      if (jsonData.code === "0000") {
        this.list[index] = {
          ...video,
          collectionNum: jsonData.data.num,
          collection: jsonData.data.status,
        };
      }
    },
  },
};
</script>
